import { Injectable } from '@angular/core';
import { ConnectionCenterStore } from './connection-center.store';
import {
  mapVendorProfileProcessSubprocess,
  VendorAPI
} from 'sustainment-component';
import { CapabilityQuery } from '../capability/capability.query';
import { IPendingConnections, SupplierListModel } from 'sustainment-component';

@Injectable({ providedIn: 'root' })
export class ConnectionCenterAction {
  public constructor(
    private store: ConnectionCenterStore,
    private api: VendorAPI,
    private capabilityQuery: CapabilityQuery
  ) {}

  public getConnections(): void {
    this.store.setLoading(true);
    this.capabilityQuery.select().subscribe((capabilities) => {
      this.api.getConnections().subscribe(
        (r) => {
          if (r) {
            r = r.map((connection) => {
              const processes = mapVendorProfileProcessSubprocess(
                connection,
                capabilities.processList
              );

              return { ...connection, processes: processes };
            });
          }

          this.store.update({ connections: r });
          this.store.setLoading(false);
        },
        () => {
          this.store.setLoading(false);
        }
      );
    });
  }

  public setConnections(connections: SupplierListModel[]): void {
    this.capabilityQuery.select().subscribe((capabilities) => {
      if (connections) {
        connections = connections.map((connection) => {
          const processes = mapVendorProfileProcessSubprocess(
            connection,
            capabilities.processList
          );

          return { ...connection, processes: processes };
        });
      }

      this.store.update({ connections: connections });
    });
  }

  public setPendingConnections(pendingConnections: IPendingConnections): void {
    this.capabilityQuery.select().subscribe((capabilities) => {
      if (pendingConnections && pendingConnections.received) {
        pendingConnections.received.data = pendingConnections.received.data.map(
          (connection) => {
            const processes = mapVendorProfileProcessSubprocess(
              connection,
              capabilities.processList
            );

            return { ...connection, processes: processes };
          }
        );
      }

      if (pendingConnections && pendingConnections.sent) {
        pendingConnections.sent.data = pendingConnections.sent.data.map(
          (connection) => {
            const processes = mapVendorProfileProcessSubprocess(
              connection,
              capabilities.processList
            );

            return { ...connection, processes: processes };
          }
        );
      }

      this.store.update({ requests: pendingConnections });
    });
  }

  public getPendingConnections(): void {
    this.store.setLoading(true);
    this.capabilityQuery.select().subscribe((capabilities) => {
      this.api.getPendingConnections().subscribe(
        (r) => {
          if (r && r.received) {
            r.received.data = r.received.data.map((connection) => {
              const processes = mapVendorProfileProcessSubprocess(
                connection,
                capabilities.processList
              );

              return { ...connection, processes: processes };
            });
          }

          if (r && r.sent && r.sent) {
            r.sent.data = r.sent.data.map((connection) => {
              const processes = mapVendorProfileProcessSubprocess(
                connection,
                capabilities.processList
              );

              return { ...connection, processes: processes };
            });
          }

          this.store.update({ requests: r });
          this.store.setLoading(false);
        },
        () => {
          this.store.setLoading(false);
        }
      );
    });
  }

  public acceptRequest(senderId: string): void {
    const value = this.store.getValue();
    if (!value?.requests?.received) return;
    const requests = [...value.requests.received.data];
    if (!requests) return;
    const acceptedRequestIndex = requests.findIndex(
      (c) => c.sustainmentId === senderId
    );
    if (acceptedRequestIndex < 0) return;
    const acceptedRequest = requests.splice(acceptedRequestIndex, 1)[0];

    acceptedRequest.connected = true;
    acceptedRequest.wantsToConnect = false;

    const connections = [...value.connections];
    connections.push(acceptedRequest);

    const requestsStore: IPendingConnections = {
      ...value.requests,
      received: { data: requests, total: requests.length }
    };

    this.store.update({ connections: connections, requests: requestsStore });
  }
}
